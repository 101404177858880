import clsx from 'clsx';
import { useCallback, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import FormButton from '../../../../components/ui/FormButton';
import { useAppContext } from '../../../../frontend/AppContext';


export default function NotConfiguredBody() {

    const tenantCtx = useAppContext();

    const router = useRouter();

    const [isNavigating, setNavigateing] = useState(false)

    const homeUrl = useMemo(() => {
        if (tenantCtx?.tenant?.menuItems && tenantCtx?.tenant.menuItems.length > 0) {
            var link = tenantCtx?.tenant.menuItems[0];

            if (link) {
                return link;
            }
        }

        return null;
    }, [])

    const handleNavigate = useCallback(
        (e) => {

            e.preventDefault();

            if (isNavigating)
                return;

            if (homeUrl && homeUrl.url) {
                setNavigateing(true)
                router.push(homeUrl.url)
            }
        },
        [homeUrl],
    )


    return <>
        <h1 id="header" className="mx-auto mt-3 text-center text-xl font-bold leading-tight tracking-tight text-gray-900 pl-1">
            {tenantCtx?.tenant?.texts.authErrorUnauthorised ?? "Unauthorised"}
        </h1>

        <h3 id="header-2 py-3"
            className={clsx("mt-3 pt-2 text-sm text-center font-sans",
                "font-semibold text-gray-600"
            )}>
            {
                tenantCtx?.tenant?.texts.authErrorUnauthorisedInfo ?? "This content is not publicly available. Please return to our home page."
            }
        </h3>

        {
            homeUrl?.url && <FormButton
                id="form-button"
                disabled={isNavigating}
                onClick={handleNavigate}>
                {
                    homeUrl?.title ?? "Return"
                }
            </FormButton>
        }

    </>
}